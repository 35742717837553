export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'MEDICAL DISTRIBUTION SPECIALISTS',
  headline: 'TENONEMED',
  description:
    'Tenonemed is a privately-owned company operating out of Johannesburg, South Africa. We supply a large range of top quality medical products across the country',
  buttonLabel: 'More Info',
  imgStart: '',
  img: 'images/logo1.jpg',
  alt: 'Credit Card',
  showButton: true,
  url: '/services'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'PRODUCTS AND SERVICES',
  headline: 'MEDICAL DISTRIBUTION',
  description:
    "Tenonemed's product mix currently consists of a variety of different products for all medical and surgical needs, consisting of surgical consumables and specialty products which are used on a daily basis",
  buttonLabel: 'Learn More',
  imgStart: 'start',
  img: 'images/logo2.jpg',
  alt: 'Vault',
  showButton: false
  
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'service and support',
  headline: 'SUPPLIER OF CHOICE',
  description:
    "With attention to service and customer relations, you can be assured that Tenonemed Medical is your supplier of choice. We have a dedicated team to attend to all your needs with emphasis on service and support. All our products carry international certifications, to ensure quality standards across all furniture, equipment and medical consumables.Our aim is satisfying all our customers' needs, so if we don't have it, we will source it for you!",
  buttonLabel: 'Contact Us',
  imgStart: 'end',
  img: 'images/logo5.jpg',
  alt: 'Vault',
  showButton: true,
  url: '/contact-us'
};

export const homeObjFour = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'DATA ANALYTICS',
  headline: 'Every transaction is stored on our secure cloud database',
  description:
    'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: 'images/svg-8.svg',
  alt: 'Vault'
};
