import React from 'react';
import IconPackDisplay from '../../IconPack/IconPackDisplay';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';


function Services() {
  return (
    <>
    <HeroSection {...homeObjOne} />
    <IconPackDisplay/>
    </>

  );
}

export default Services;
