export const homeObjOneCon = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
    headline: 'CONTACT INFORMATION',
    description:
      'We provide worldwide shipping to all countries. If there are any issues, just issue a refund and we will process your request',
    buttonLabel: 'Shop Now',
    imgStart: '',
    img: 'images/logo3.jpg',
    alt: 'Credit Card',
    email: 'Tenone.med@gmail.com',
    contactName: 'Ethel Nthite',
    contactNumber: '071 767 3066',
    Addressln1: '11 Oscar road',
    Addressln2: 'Hughes, Boksburg',
    Addressln3: 'Gauteng',
  };