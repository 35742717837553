import React from 'react'
import IconPack from './IconPack'
import './IconPack.css'

function IconPackDisplay() {
    return (
        <div className='icon_pack_main_wrapper'>
            <IconPack />
        </div>
    )
}

export default IconPackDisplay