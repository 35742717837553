import React from 'react'

import { homeObjOneCon } from './DataCon';
import ContactUsPage from './ContactUsPage';

function ContactUsMainDisp() {
  return (
    <ContactUsPage {...homeObjOneCon} />
  )
}

export default ContactUsMainDisp